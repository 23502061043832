import Vue from "vue";
import VueRouter from "vue-router";
import Step1 from "../components/Step1.vue";
import Step2 from "../components/Step2.vue";
import Step4 from "../components/Step4.vue";
import Resumen from "../components/Finish.vue";
import Planes from "../components/PlanForm.vue";
// import Planes from "../components/Planes.vue";
import Beneficios from "../components/Beneficios.vue";
import BenefEscritorio from "../components/BenefEscritorio.vue";
import BenefMovil from "../components/BenefEscritorio.vue";

import Location from "../components/Location.vue";
import Location2 from "../components/Location2.vue";
import Erorr from "../components/Error.vue";
import Error2 from "../components/Error2.vue";

import planMovil from "../components/PlanMovil.vue";
import PlanEscritorio from "../components/PlanEscritorio.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/hg/",
  },
  {
    path: "/hg/",
    name: "Step1",
    component: Step1,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/planes",
    name: "planes",
    component: Planes,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/beneficios",
    name: "beneficios",
    component: Beneficios,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg//benefEscritorio",
    name: "benefEscritorio",
    component: BenefEscritorio,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/benefMovil",
    name: "benefMovil",
    component: BenefMovil,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/mascotas",
    name: "Step2",
    component: Step2,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/location",
    name: "location",
    component: Location,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/location2",
    name: "location2",
    component: Location2,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/planEscritorio",
    name: "PlanEscritorio",
    component: PlanEscritorio,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/planMovil",
    name: "planMovil",
    component: planMovil,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/datos",
    name: "Step4",
    component: Step4,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/resumen",
    name: "Resumen",
    component: Resumen,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/error",
    name: "Error",
    component: Erorr,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/error2",
    name: "Error2",
    component: Error2,
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    }  
  },
  {
    path: "/hg/finish",
    name: "Éxito",
    meta: {
      title: 'Southbridge - Hogar', //Titulo de la pagina
      metaTags: [
        {
          name: 'description',
          content: 'Southbridge - Hogar'//Descripcion metadata html
        },
        {
          property: 'og:description',
          content: 'Southbridge - Hogar'//open graph Descripcion metadata html
        }
      ]
    },  
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Finish.vue"),
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }; // Esto hará que siempre se desplace hacia arriba al cambiar de página
  },
});
//... Cambiar todos los fronts por router mas avanzado
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
  
});


export default router;
