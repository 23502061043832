import axios from "axios";
const { VUE_APP_SHIELD_URL, VUE_APP_SPONSOR } = process.env;

export default class ShieldService {

  static async newCreateStep(userData, petData, homeData, servicesData, step) {
    try {

      let url;
      const jsonData = { userData, petData, homeData, servicesData, step, sponsor: VUE_APP_SPONSOR };

      if (servicesData.type == 'create') {
        url = '/step/newCreate';
      } else if (servicesData.type == 'update') {
        url = '/step/newUpdate';
      }

      const carroResponse = await axios.post(
        `${VUE_APP_SHIELD_URL}${url}`,
        jsonData
      );

      return carroResponse;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error en el servicio Stepers",
      };
    }
  }

  static async newGetStepAbandoned(stepId) {

    const shieldRes = await axios.get(
      `${VUE_APP_SHIELD_URL}/step/newGetAbandoned/${stepId}`
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async createContactActiveCampain(userData, servicesData) {

    try {
      const shieldRes = await axios.post(`${VUE_APP_SHIELD_URL}/activeCampain/createContact`,
        {
          userData,
          servicesData,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al crear el contacto",
      };
    }
  }

  static async updateContactActiveCampain(userData, planData, servicesData, index) {

    try {

      const shieldRes = await axios.put(`${VUE_APP_SHIELD_URL}/activeCampain/updateContact`,
        {
          userData,
          planData,
          servicesData,
          index,
          sponsor: VUE_APP_SPONSOR,
        }
      );

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async getContactActiveCampain(email) {

    try {

      const shieldRes = await axios.get(`${VUE_APP_SHIELD_URL}/activeCampain/getContact/${email}`);

      return shieldRes.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrió un error al actualizar el contacto",
      };
    }
  }

  static async createInsurance(person, pet, home, stepId) {

    if(pet) {
      pet.species = pet.species.id;
      pet.sex = pet.sex.id;
    }

    person.birthdate = person.dates;

    // const jsonData = { home, person, pet, planId: plan._id, stepId: stepId };
    const jsonData = { home, person, pet, stepId };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/hogar/createWithRunWayPayment`,
      jsonData
    );

    if (shieldRes.data.status === "ok") return shieldRes.data.data.link;

    else throw shieldRes.data;
  }

  static async createInsuranceWithToku(person, pet, home, plan, stepId, seguroMascota) {

    var sponsorTokuRedirect = process.env.VUE_APP_SPONSOR_TOKU_REDIRECT

    if (seguroMascota == false) {
      pet = null;
    }

    const jsonData = { home, person, pet, stepId: stepId, sponsorTokuRedirect: sponsorTokuRedirect };

    const shieldRes = await axios.post(
      `${VUE_APP_SHIELD_URL}/hogar/createWithToku`,
      jsonData
    );
    if (shieldRes.data.status === "ok") return shieldRes.data.data;
    else throw shieldRes.data;
  }

  static async getUf() {
    try {
      const shieldRes = await axios.get(
        `${VUE_APP_SHIELD_URL}/hogar/getUf`,
      );

      return shieldRes.data.status === "ok" ? shieldRes.data.data : [];
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las uf",
      };
    }
  }


}


