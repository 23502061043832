<template>
    <v-container style="margin: auto;">

        <v-form>

            <h3 class="text-center tittle">
                ¿Quieres agregar coberturas o asistencias adicionales a tu seguro?
            </h3>

            <p></p>
            <p class="textoSub">Puedes usar la barra para elegir el monto o el botón ayuda para guiarte.</p>

            <div class="box-conteiner">
                <v-row>
                    <div class="purple-image">
                        <img class="imagen" :src="asistenciaHogar" alt="Image 1">
                    </div>
                    <div style=" padding-top: 1%;">
                        <div class="text-subTittle">
                            Asistencia Hogar Reparaciones
                        </div>
                        <br>
                        <div class="text-content">
                            Con este servicio contarás con ayuda para reparar los diferentes problemas de urgencia <br>
                            que pueden ocurrir en tu hogar, ya sea de Gasfitería, Electricidad Cristalería y Plomería.
                            <br>
                            También incluye ayuda para la instalación de televisores, lavadoras, cortinas y tambien <br>
                            artefactos de gas.
                            <br>
                            <br>
                            <p class="text-blue"> <a class="text-blue" :href="documentHogarReparaciones"
                                    target="_blank">Conoce más aquí.</a></p>
                        </div>
                    </div>
                    <div class="box-incluido2">
                        incluido
                    </div>
                </v-row>
            </div>

            <div class="box-conteiner">
                <v-row>
                    <div class="purple-image">
                        <img class="imagen" :src="gastosMedicos" alt="Image 1">
                    </div>
                    <div style=" padding-top: 1%;">
                        <div class="text-subTittle">
                            Protección Gastos Médicos Asesora del hogar
                        </div>
                        <br>
                        <div class="text-content">
                            Protege financieramente la salud de quienes trabajan en tu casa como Asesoras del hogar,
                            <br>
                            Enfermeras,
                            cuidadores, entre otros, que tengan contrato de trabajo con domicilio en tu <br> hogar
                            asegurado.
                            <br><br>
                            Con esta cobertura, en caso de que ellos sufran un accidente y tengan gastos de <br>
                            asistencia
                            médica, farmacéutica y hospitalaria, reembolsaremos los gastos no cubiertos <br> por su
                            previsión de
                            salud y seguros de salud, por hasta 100UF.
                            <br>
                            <br>
                            <p class="text-blue"> <a class="text-blue" :href="documentGastosMedicos"
                                    target="_blank">Conoce más aquí.</a></p>
                        </div>
                    </div>
                    <div class="box-incluido"
                        style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

                        <v-checkbox color="checkbox" v-model="homeData.infoBenef.gastosMedicos"
                            @change="calculatePrice()">
                        </v-checkbox>

                        <p v-if="!homeData.infoBenef.gastosMedicos">Agregar<br><br> {{
                            formatPrice(homeData.valores.valorGastosMedicos)
                            }}<br><br>({{ homeData.valores.valorGastosMedicosUF.toFixed(4) }} UF)</p>


                        <p v-if="homeData.infoBenef.gastosMedicos">Quitar<br><br> </p>
                    </div>
                </v-row>
            </div>

            <div class="box-conteiner1">
                <v-row>
                    <div class="purple-image">
                        <img class="imagen" :src="asistenciaMedica" alt="Image 1">
                    </div>
                    <div style="margin-right: 13%; padding-top: 1%;">
                        <div class="text-subTittle">
                            Asistencia Médica Online Full Familia
                        </div>
                        <br>
                        <div class="text-content">
                            Sin salir de tu casa podrás tener la asistencia y guía de una red médicos acreditado para
                            <br>
                            ayudarte con a tí y tu familia con
                            tus problemas de salud, excelente opción antes de <br> acudir a un centro médico.
                            <br><br>
                            Nuestros médicos podrán ayudarte con información, recomendación, indicación, <br>
                            orientación
                            diagnóstica, prescripción o tratamiento según sea el caso, todo desde tu PC <br> o celular
                            en la
                            comodidad
                            de tu hogar asegurado. Incluye un seguro de muerte <br> accidental por 50UF en caso que algo
                            te
                            ocurra.
                            <br>
                            <br>
                            <p class="text-blue"> <a class="text-blue" :href="documentTelemedicina"
                                    target="_blank">Conoce más aquí.</a></p>
                        </div>
                    </div>
                    <div class="box-incluido1"
                        style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

                        <v-checkbox color="checkbox" v-model="homeData.infoBenef.asistenciaMedica"
                            @change="calculatePrice()"></v-checkbox>
                        <p v-if="!homeData.infoBenef.asistenciaMedica">Agregar<br><br> {{
                            formatPrice(homeData.valores.valorAsistenciaMedica) }} <br><br> ({{
                                homeData.valores.valorAsistenciaMedicaUF.toFixed(4) }} UF)
                        </p>
                        <p v-if="homeData.infoBenef.asistenciaMedica">Quitar<br><br> </p>
                    </div>
                </v-row>
            </div>

            <!-- <div class="box-conteiner1">
                <v-row>
                    <div class="purple-image">
                        <img class="imagen" :src="seguroMascota" alt="Image 1">
                    </div>
                    <div style="margin-right: 13%; padding-top: 1%;">
                        <div class="text-subTittle">
                            Seguro Mascota protegida
                        </div>
                        <br>
                        <div class="text-content">
                            Protege la salud y bienestar de tu mascotas con las asistencias para Perros y Gatos que <br>
                            tenemos disponibles para tu familia.
                            <br><br>
                            Desde consultas médicas a domicilio por urgencia, baño y peluquería. Incluye un seguro <br> de
                            Responsabilidad civil si tu mascota daña a una persona u otra mascota. Orientación <br> Médica
                            y legal en caso que algo ocurra con tu mascota, etc.
                            <br>
                            <br>
                            <p class="text-blue"> <a class="text-blue" :href="documentMacotas" target="_blank">Conoce más aquí.</a></p>
                            
                        </div>
                    </div>
                    <div class="box-incluido1"
                        style="display: flex; align-items: center; justify-content: center; flex-direction: column;">

                        <v-checkbox color="checkbox" v-model="homeData.infoBenef.seguroMascota" @change="calculatePrice()"></v-checkbox>
                        <p v-if="!homeData.infoBenef.seguroMascota">Agregar<br><br> {{ formatPrice(homeData.valores.valorSeguroMascota)
                        }} <br><br> ({{ homeData.valores.valorSeguroMascotaUF.toFixed(4) }} UF)</p>
                        <p v-if="homeData.infoBenef.seguroMascota">Quitar<br><br> </p>

                    </div>
                </v-row>
            </div> -->
            <br><br><br>


        </v-form>

        <div style="background-color: white;" class="" :class="{ 'sticky-footer': isSticky }">
            <BotLine />
            <v-row class="precioPlan">
                <p
                    style="margin-top: 1vw;  margin-left: 10%; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 0.5%;">
                    Tu plan mensual: </p>
                <p
                    style="margin-top: 0.8vw; font-size: 20px; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 0.5%; ">
                    {{ formatPrice(homeData.priceCLP) }} </p>
                <p style="margin-top: 1vw; color: var(--AD-Primario-1, #2D387C); font-weight: 700; margin-right: 40%;">
                    (UF
                    {{ Number(homeData.priceUF).toFixed(2) }})</p>
            </v-row>
        </div>

        <div class="button-container">
            <v-btn class="button-continuar" style="background-color: #2D387C; height: 43px; margin-left: 70%;"
                @click="validate()">
                Continuar
            </v-btn>
        </div>
    </v-container>
</template>


<script>

import asistenciaHogar from '@/assets/images/asistenciaHogar.png';
import gastosMedicos from '@/assets/images/gastosMedicos.png';
import asistenciaMedica from '@/assets/images/asistenciaMedica.png';
import seguroMascota from '@/assets/images/seguroMascota.png';

import BotLine from "@/components/BotLine.vue";

import ShieldService from "../services/shield.service";
import Dynamic from "../services/dynamic.service";

import CryptoJS from 'crypto-js';

export default {
    name: "Beneficios",
    props: ['userData', 'homeData', 'servicesData'],
    components: { BotLine },
    data: () => ({
        secretKey: process.env.VUE_APP_CRYPT,
        isSticky: true,

        documentHogarReparaciones: '/documents/AsistenciaHogar.pdf',
        documentGastosMedicos: '/documents/GastosMedicos.pdf',
        documentTelemedicina: '/documents/AsistenciaTelemedicina.pdf',
        documentMacotas: '/documents/AsistenciaMascotas.pdf',

        asistenciaHogar,
        gastosMedicos,
        asistenciaMedica,
        seguroMascota,

        e1: 3,

        activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',

        formatter: new Intl.NumberFormat("es-CL", {
            style: "currency",
            currency: "CLP",
        }),
    }),
    mounted() {
        window.addEventListener('scroll', this.handleScroll);

        this.calculatePrice();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        async validate() {

            const homeDataLocal = JSON.stringify(this.homeData);
            const encryptedHomeDataLocal = CryptoJS.AES.encrypt(homeDataLocal, this.secretKey).toString();
            localStorage.setItem('homeData', encryptedHomeDataLocal);

            try {

                await ShieldService.newCreateStep(null, null, this.homeData, this.servicesData, 'Beneficios');

            } catch (error) {
                console.log("Cart Fail");
            }

            if (this.userData.email && this.activeCampaingUse == 'TRUE') {
                const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

                this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;

                await ShieldService.updateContactActiveCampain(null, this.planDataAux, this.servicesData, 1);

                const servicesDataLocal = JSON.stringify(this.servicesData);
                const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
                localStorage.setItem('servicesData', encryptedServicesDataLocal);

            }

            if (this.homeData.infoBenef.seguroMascota == true) {
                this.$router.push({ path: "/hg/mascotas" });
            } else {
                if (this.userData.tipoPropiedad == "casa") {
                    this.$router.push({ path: "/hg/location" });
                } else if (this.userData.tipoPropiedad == "departamento") {
                    this.$router.push({ path: "/hg/location2" });
                }
            }

        },
        async change() {
        },
        formatPrice(number) {
            return this.formatter.format(number);
        },
        async calculatePrice() {

            const ufShield = await ShieldService.getUf()
            const uf = ufShield.data

            // There we have to change between differeents types of coverages
            let incendioEstructura = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'estructura' ? 'building_fire' : null
            let incendioContenido = this.userData.recomendacion == 'estructura y contenido' || this.userData.recomendacion == 'contenido' ? 'content_fire' : null
            let sismoEstructura = this.homeData.infoBenef.sismoEstructura == true ? 'building_earthquake' : null
            let sismoContenido = this.homeData.infoBenef.sismoContenido == true ? 'content_earthquake' : null
            let roboContenido = this.homeData.infoBenef.roboContenido == true ? 'content_theft' : null

            const userCoverages = [incendioEstructura, incendioContenido, sismoEstructura, sismoContenido, roboContenido]
            const userInputCoverages = userCoverages.filter(cov => cov != null)

            let gastosMedicos = this.homeData.infoBenef.gastosMedicos == true ? 'medical_refund' : null
            let asistenciaMedica = this.homeData.infoBenef.asistenciaMedica == true ? 'telemedicine_assistance' : null
            let seguroMascota = this.homeData.infoBenef.seguroMascota == true ? 'pet_assistance' : null
            const userAggregates = [gastosMedicos, asistenciaMedica, seguroMascota]
            const userInputAggregates = userAggregates.filter(agg => agg != null)

            const userInput = {
                usrCoverages: userInputCoverages,
                usrAggregates: userInputAggregates,
                usrCapitals: {
                    "building": this.homeData.valoresUf.estructuraSlider,
                    "content": this.homeData.valoresUf.contenidoSlider,
                    "content_theft": this.homeData.valoresUf.contenidoSlider * 0.6 // change 60%
                }
            }

            const results = Dynamic.calculate(userInput);
            const options = Dynamic.calculateAllOptions(userInput);

            if (this.homeData.discount.active) {
                const homeAssistance = (options.aggregates.find(a => a.aggregate === 'home_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
                const telemedeicineAssistance = (options.aggregates.find(a => a.aggregate === 'telemedicine_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
                const petAssistance = (options.aggregates.find(a => a.aggregate === 'pet_assistance')?.aggregatePrice ?? 0) * this.homeData.discount.value
                const medicalRefunds = (options.aggregates.find(a => a.aggregate === 'medical_refund')?.aggregatePrice ?? 0) * this.homeData.discount.value

                this.homeData.valores.valorAsistenciaHogar = ((homeAssistance * uf) / 12)
                this.homeData.valores.valorAsistenciaMedica = ((telemedeicineAssistance * uf) / 12)
                this.homeData.valores.valorSeguroMascota = ((petAssistance * uf) / 12)
                this.homeData.valores.valorGastosMedicos = ((medicalRefunds * uf) / 12)

                // this.homeData.valores.valorAsistenciaHogar = ((homeAssistance * uf) / 12) * this.homeData.discount.value
                // this.homeData.valores.valorAsistenciaMedica = ((telemedeicineAssistance * uf) / 12) * this.homeData.discount.value
                // this.homeData.valores.valorSeguroMascota = ((petAssistance * uf) / 12) * this.homeData.discount.value
                // this.homeData.valores.valorGastosMedicos = ((medicalRefunds * uf) / 12) * this.homeData.discount.value

                this.homeData.valores.valorGastosMedicosUF = (medicalRefunds / 12)  // Valores Uf 
                this.homeData.valores.valorAsistenciaMedicaUF = (telemedeicineAssistance / 12)  // Valores Uf 
                this.homeData.valores.valorSeguroMascotaUF = (petAssistance / 12)  // Valores Uf 
                this.homeData.valores.valorAsistenciaHogarUF = (homeAssistance / 12)  // Valores Uf 

                // this.homeData.valores.valorGastosMedicosUF = (medicalRefunds / 12) * this.homeData.discount.value // Valores Uf 
                // this.homeData.valores.valorAsistenciaMedicaUF = (telemedeicineAssistance / 12) * this.homeData.discount.value // Valores Uf 
                // this.homeData.valores.valorSeguroMascotaUF = (petAssistance / 12) * this.homeData.discount.value // Valores Uf 
                // this.homeData.valores.valorAsistenciaHogarUF = (homeAssistance / 12) * this.homeData.discount.value // Valores Uf 

                this.homeData.priceUF = (Number(results.total / 12)) * this.homeData.discount.value

            } else {
                const homeAssistance = options.aggregates.find(a => a.aggregate === 'home_assistance')?.aggregatePrice ?? 0
                const telemedeicineAssistance = options.aggregates.find(a => a.aggregate === 'telemedicine_assistance')?.aggregatePrice ?? 0
                const petAssistance = options.aggregates.find(a => a.aggregate === 'pet_assistance')?.aggregatePrice ?? 0
                const medicalRefunds = options.aggregates.find(a => a.aggregate === 'medical_refund')?.aggregatePrice ?? 0

                this.homeData.valores.valorAsistenciaHogar = (homeAssistance * uf) / 12
                this.homeData.valores.valorAsistenciaMedica = (telemedeicineAssistance * uf) / 12
                this.homeData.valores.valorSeguroMascota = (petAssistance * uf) / 12
                this.homeData.valores.valorGastosMedicos = (medicalRefunds * uf) / 12

                this.homeData.valores.valorGastosMedicosUF = medicalRefunds / 12 // Valores Uf 
                this.homeData.valores.valorAsistenciaMedicaUF = telemedeicineAssistance / 12 // Valores Uf 
                this.homeData.valores.valorSeguroMascotaUF = petAssistance / 12 // Valores Uf 
                this.homeData.valores.valorAsistenciaHogarUF = homeAssistance / 12 // Valores Uf 

                this.homeData.priceUF = Number(results.total / 12)
            }

            this.homeData.priceCLP = (this.homeData.priceUF * uf);
        },
        handleScroll() {
            // const footer = this.$el;
            // const footerOffset = footer.offsetTop;
            // const scrollTop = window.scrollY || window.pageYOffset;

            // if (scrollTop > footerOffset) {
            //     this.isSticky = true;
            // } else {
            //     this.isSticky = false;
            // }
        },

    }
}

</script>

<style lang="scss" scoped>
.tittle {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: -0.01em;
    color: #2D387C;
}

.textoSub {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: center;
}

.box-conteiner {
    background: var(--AD-Primario-2, #ebebf6);
    border-radius: 8px;
    margin-top: 5%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 925px;
}

.box-conteiner1 {
    background: var(--AD-Primario-2, #ebebf6);
    border-radius: 8px;
    margin-top: 5%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 925px;
}

.purple-image {
    margin-right: 2%;
    width: 10%;
    padding-left: 1.5%;
    padding-top: 1%;
    margin-left: 2%;
    margin-top: 1%;
    background: var(--AD-Secundario-1, #4F74E3);
    border-radius: 6px;
    width: 60px;
    height: 60px;
    flex-shrink: 0;
    position: relative;
}

.imagen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
}

.text-subTittle {
    color: var(--AD-Primario-1, #070783);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.text-content {
    color: var(--AD-Texto-1, #333);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    padding-bottom: 1%;
}

.text-blue {
    font-family: Arial, Helvetica, sans-serif;
    color: #4F74E3;
    text-decoration: underline
}

.box-incluido2 {
    background: var(--AD-Primario-2, #4F74E3);
    border-radius: 8px;
    padding-right: 5.5%;
    padding-left: 5.5%;
    padding-top: 6%;
    padding-bottom: 6%;
    color: #FFF;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: nohermal;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    min-width: 170px;
}

.box-incluido {
    background: var(--AD-Primario-2, #4F74E3);
    border-radius: 8px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 6%;
    padding-bottom: 6%;
    color: #FFF;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: nohermal;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    min-width: 170px;
}

.box-incluido1 {
    background: var(--AD-Primario-2, #4F74E3);
    border-radius: 8px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 6%;
    padding-bottom: 6%;
    color: #FFF;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-style: nohermal;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    min-width: 170px;
}

.tu-plan {
    margin-left: 10%;
    margin-right: 40%;
    color: var(--AD-Primario-1, #2D387C);
    font-size: 16px;
    font-weight: 700;
}

.button-continuar {
    color: white;
    display: flex;
    width: 252px;
    padding: 12px 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
}

.sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}

@media only screen and (max-width: 1500px) {
    .precioPlan {
        padding-bottom: 4%;
        margin-top: -10%;
        background-color: rgb(255, 255, 255);
    }
}

@media only screen and (min-width: 1500px) {
    .precioPlan {
        padding-bottom: 3%;
        margin-top: -7%;
        background-color: rgb(255, 255, 255);
    }
}
</style>